<template>
    <!--编辑页与详情页相同-->
    <div class="CheckEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="editForm"
            label-width="80px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构类型">
                            <el-input :value="formExtend.deptTypeName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input :value="formExtend.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly :value="formExtend.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input readonly :value="formExtend.createTime" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件" prop="search">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="盘点类型" prop="verifyFlag">
                            <el-select v-model="form.verifyFlag" placeholder="请选择">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <div v-if="form.verifyFlag === false" style="color: #f56c6c; font-size: 12px; line-height: 1">
                            非营业盘点不同步收银机离线数据，离线数据未同步会导致盈亏数量偏差。
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" :disabled="disableQueryAndAddButton" @click="queryAndAdd" size="small">
                查询并添加
            </el-button>
            <el-button type="primary" :disabled="disableShowPickUpGoodsDialog" @click="showPickUpGoods" size="small">
                选择商品基本信息
            </el-button>
            <!--            <el-button-->
            <!--                :loading="saveLoading"-->
            <!--                type="primary"-->
            <!--                :disabled="disableSaveButton"-->
            <!--                @click="handleSave"-->
            <!--                size="small"-->
            <!--                >保存</el-button-->
            <!--            >-->
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 500px; width: 100%">
                <el-table
                    id="checkEditTable"
                    border
                    ref="checkEditTable"
                    stripe
                    v-loading="loading"
                    :data="form.detailParams"
                    size="mini"
                    max-height="440"
                >
                    <el-table-column type="selection" width="55" align="center" />
                    <el-table-column label="序号" type="index" width="50" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180" fixed="left" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="120" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                        </template>
                    </el-table-column>
                    <el-table-column label="助记码" width="80">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).fastBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="120">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="120">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="初盘快照数量" width="100" v-if="hasPrivilege('biz.stock.query')">
                        <template slot-scope="scope">
                            <div :prop="'detailParams.' + scope.$index + '.snapshotCounts'" :rules="rules.counts">
                                {{ scope.row.snapshotCounts }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="btnBox">
                    <el-button
                        :loading="saveLoading"
                        :disabled="disableSaveButton"
                        @click="handleSave(true)"
                        size="medium"
                        >暂存</el-button
                    >
                    <el-button
                        type="primary"
                        :loading="saveLoading"
                        :disabled="disableSaveButton"
                        @click="handleSave(false)"
                        size="medium"
                        >提交</el-button
                    >
                </div>
            </div>
        </el-card>
        <pick-up-goods
            ref="pickupGoods"
            :categoryCode="form.categoryCode"
            :brandCode="form.brandCode"
            :selfCanClose="false"
            @queryGoods="setSelectedGoods"
        />
    </div>
</template>
<script>
import PickUpGoods from 'components/PickUpGoods';
import EfRemark from 'components/EfRemark';
import Util from 'js/Util';
import StockBizCommon, { goodsStockProperty, PropertyConverter } from 'js/StockBizCommon';
import GoodsCommon from 'js/GoodsCommon';

const detailParamPropertyArr = ['codes', 'goodsCode', 'counts'];
export default {
    name: 'CheckEdit',
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    components: {
        PickUpGoods,
        EfRemark,
    },
    data() {
        return {
            search: '',
            formExtend: {
                deptTypeName: '',
                createTime: '',
                creator: '',
                deptName: '',
                firstCheckSnapshotCounts: [],
            },
            form: {
                categoryCode: '',
                brandCode: '',
                code: '',
                deptCode: '',
                remark: '',
                detailParams: [],
                delCodes: [],
                verifyFlag: '',
            },
            options: [
                {
                    value: true,
                    label: '营业盘点',
                },
                {
                    value: false,
                    label: '非营业盘点',
                },
            ],
            detailParamPropertyArrForShowSubmit: [...detailParamPropertyArr, 'snapshotCounts'],
            detailParamPropertyArrForAdd: [
                ...detailParamPropertyArr,
                new PropertyConverter(goodsStockProperty, 'snapshotCounts'),
            ],
            goodsDetailMap: new Map(),
            rules: {
                verifyFlag: [{ required: true, message: '请选择盘点类型', trigger: 'change' }],

                // deptCode: deptCodeValidateRule(),
                // abstractCode: abstractCodeValidateRule(),
                // remark: remarkNotRequiredValidateRule(),
                // counts: countValidateRule(
                //     () => (this.form || {}).detailParams || [],
                //     (goodsCode) => this.skuByGoodsCode(goodsCode)
                // ),
            },
            loading: false,
            saveLoading: false,
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await this.$efApi.stockCheckApi.detail(this.code);
            Util.copyProperties(rst, this.form);
            Util.copyProperties(rst, this.formExtend);
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                rst,
                this.detailParamPropertyArrForShowSubmit
            );
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
            this.form.detailParams.forEach((item, index) => {
                item.snapshotCounts = rst.firstCheckSnapshotCounts[index];
            });
            this.loading = false;
        })();
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            const deptCode = this.form.deptCode;
            const search = this.search;
            return deptCode == null || deptCode === '' || search == null || search === '';
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            const deptCode = this.form.deptCode;
            return deptCode == null || deptCode === '';
        },
        //禁用/启用按钮
        disableSaveButton() {
            const deptCode = this.form.deptCode;
            const detailParams = this.form.detailParams;
            return deptCode == null || deptCode === '' || detailParams == null || detailParams.length === 0;
        },
    },
    methods: {
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ deptCode: this.form.deptCode, search: this.search });
            this.setSelectedGoods(rst.data);
        },
        handleDelete(index) {
            const detailCode = this.form.detailParams.splice(index, 1)[0].codes;
            if (Util.isEmpty(detailCode)) {
                //不存在，说明是单据详情中新增的商品被删除，则不需要处理
                return;
            }
            this.form.delCodes.push(detailCode);
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.deptType = deptType;
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.form.deptCode }, this.formExtend.deptName);
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
                this.$refs.pickupGoods.dialogFormVisible = false;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        handleSave(type) {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                const params = {
                    code: this.code,
                    deptCode: this.form.deptCode,
                    goodsCodes: this.form.detailParams.map((i) => i.goodsCode),
                    remark: this.form.remark,
                    verifyFlag: this.form.verifyFlag,
                };
                if (type) {
                    // 暂存
                    await this.$efApi.stockCheckApi.updateTemporaryStorage(params).finally(() => {
                        this.saveLoading = false;
                    });
                    this.goBackAndReload();
                } else {
                    // 提交
                    await this.$efApi.stockCheckApi.updateNewlyBuildSubmit(params).finally(() => {
                        this.saveLoading = false;
                    });
                    this.goBackAndReload();
                }
                this.saveLoading = true;
            });
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
.btnBox {
    margin-top: 40px;
    margin-bottom: 100px;
}
</style>
